.calendar {
  border-radius: 5px;
  width: 100%;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__tile--now {
  background: #a5b1ff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #006edc;
}

@media only screen and (max-width: 600px) {
  .react-calendar__tile {
    padding: 5px 3.33px;
  }
  .calendar {
    width: 250px;
  }
}

@media only screen and (max-width: 300px) {
  .react-calendar__tile {
    padding: 0;
  }
  .calendar {
    width: 160px;
  }
  .react-calendar__navigation button {
    min-width: 22px;
    background: none;
  }
}
