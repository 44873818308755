.rbc-calendar {
  @media (max-width: 1536px) {
    border-top: none;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.rbc-event-content {
  font-size: 0.8rem;
}

.rbc-toolbar button.rbc-active {
  background-color: #00142a;
  color: #ffd1c0 !important;
}

.rbc-toolbar button {
  color: #000;
  cursor: pointer;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  background-color: #00142a;
  color: #ffd1c0 !important;
}

